/**
 * @param {string} selector for the language toggle link in the MVC header
 * @param {string} language Current language of the Sitecore site
 * @param {object} languageSelectors From the Sitecore site's state
 */

const updateLanguageLink = (selector, language, languageSelectors) => {
  const element = document.querySelector(selector);
  const targetLanguage = languageSelectors.find(l => l.code !== language);
  if (!element) return;

  // 404 errors have /sitecore in the languageSelector url, for some reason. Link to home.
  if (targetLanguage?.url.includes('/sitecore')) {
    element.href = '/';
  } else {
    element.href = targetLanguage?.url;
  }
};

export default updateLanguageLink;
