/* eslint-disable no-script-url */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const ApplicationErrorMessage = ({ is404 }) => {
  /**
   * 404 errors are rendered in the JSS app. We disable the server-side
   * render for these, because we have to manipulate the DOM a bit when the
   * component mounts. See useEffect, below.
   */
  const [shouldRender, setShouldRender] = useState(!is404);

  const linkPath = is404
    ? {
        en: 'javascript:history.go(-1)',
        fr: 'javascript:history.go(-1)',
      }
    : {
        en: '/',
        fr: '/accueil',
      };
  const copy = is404
    ? {
        en: 'to take a u-turn back to the previous page.',
        fr: 'pour faire demi-tour et retourner à la page précédente.',
      }
    : {
        en: 'to return to the Home page.',
        fr: 'pour revenir à la page d’accueil.',
      };

  useEffect(() => {
    if (is404) {
      const requestedPath = window.location.href;
      const referringPagePath = document.referrer || '';
      window.dataLayer.push({
        event: 'e_httpError',
        httpStatusCode: '404',
        referringPagePath,
        requestedPagePath: requestedPath,
      });
    }
    const bodyEl = document.getElementsByTagName('body')[0];
    const rootEl = document.getElementById('jss-root');

    /**
     * The MVC site app has a particular background colour, and the
     * app has a white backgruond colour (see public/html).
     *
     * But the error UI needs the body to have a different background
     * colour, and the app to have a transparent background.
     *  */
    rootEl.classList.add('hasError');
    bodyEl.classList.add('hasError');

    setShouldRender(true);

    return () => {
      rootEl.classList.remove('hasError');
      bodyEl.classList.remove('hasError');
    };
  }, [is404]);

  if (!shouldRender) return null;

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="https://www.honda.ca/favicon.ico" />
        <link href="https://www.honda.ca/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <title>Honda PSP Canada</title>
        <style type="text/css">
          {`
          body.hasError {
            background: #c7c7c7;
          }
          #jss-root.hasError {
            background-color: transparent;
          }
          #error-container.wrapper {
            padding: 5px;
            max-width: 982px;
            margin: 0 auto 11px;
          }
          #error-container .content-container {
            background-color: #ffffff;
            border: 1px solid #c4c4c4;
            border-radius: 6px;
            padding: 40px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
          }
          #error-container h1 {
            font-size: 31px;
            line-height: 30px;
            text-transform: uppercase;
            margin-bottom: 50px;
            color: #151515;
            font-weight: bold;
            font-family: 'NationalBold', Helvetica, Arial, sans-serif;
          }
          #error-container h2 {
            font-size: 18px;
            color: #c40020;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 20px;
            font-family: Verdana, Geneva, sans-serif;
          }
          #error-container p {
            color: #333;
            margin-bottom: 20px;
            font-family: Verdana, Geneva, sans-serif;
            font-size: 11px;
            line-height: 16px;
          }
          #error-container hr {
            border: none;
            border-top: 1px solid #e6e6e6;
            margin: 0 0 80px 0;
          }
          #error-container a {
            color: #0d9be4;
            text-decoration: none;
          }
        `}
        </style>
      </Helmet>

      <div id="error-container" className="wrapper">
        <div className="content-container" style={{ padding: '40px' }}>
          <h1>ERROR</h1>
          <h2>This page cannot be found.</h2>
          <p>
            Sorry, we didn’t mean to lead you down the wrong track. The current Honda page you are looking for might
            have been removed, had its name changed or is temporarily unavailable.
          </p>
          <p>
            <a href={linkPath.en}>Click here</a> {copy.en}
          </p>
          <hr />
          <h1>ERREUR</h1>
          <h2>Cette page ne peut être trouvée.</h2>
          <p>
            Désolé, nous n’avions pas l’intention de vous diriger dans la mauvaise direction. La page Honda que vous
            cherchez n’existe peut-être plus, son nom a pu être changé ou elle peut être temporairement hors d’usage.
          </p>
          <p>
            <a href={linkPath.fr}>Cliquez ici</a> {copy.fr}
          </p>
        </div>
      </div>
    </>
  );
};

ApplicationErrorMessage.propTypes = {
  is404: PropTypes.bool,
};

export default ApplicationErrorMessage;
